import React from 'react';  
import { Link } from 'react-router-dom';
import Email from '../assets/email.png';
import Person from '../assets/person.png';
import Discord from '../assets/discord.png';

export default function Apply() {

    return(
        <div className="apply">
            <div className="apply-container">
                <h1>Apply</h1>
                <form>
                    <div className="input" type="text">
                        <img src={Person} alt="person"/>
                        <input type="text" placeholder='Full Name' />                                   
                    </div>
                    <div className="input" type="email">
                        <img src={Email} alt="email"/>
                        <input type="email" placeholder='Email' />                                   
                    </div>
                    <div className="input" type="text">
                        <img src={Discord} alt="email"/>
                        <input type="text" placeholder='Discord Name' />                                   
                    </div>
                    <div className="input" type="text">
                        <img src={Discord} alt="email"/>
                        <input type="text" placeholder='Discord Server Link' />                                   
                    </div>
                    <div className="button-group">
                    <button className="login-button">Send Application</button>
                    </div>
                </form>
            </div>
        </div>

    )
}