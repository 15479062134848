import React from 'react';
import Header from './components/header';
import Footer from './components/footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import Apply from './pages/apply';
import Panel from './pages/panel';
import Activate from './pages/activate';
import './App.css';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedHeader from './components/ProtectedHeader';
import { AuthProvider } from './contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';
import Products from './pages/products';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCancel from './components/PaymentCancel';

function App() {
  const checkTokenValidity = () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      return false;
    }

    try {
      const decodedToken = jwtDecode(token);
      const isExpired = decodedToken.exp * 1000 < Date.now();
      return !isExpired;
    } catch (error) {
      console.error("Error decoding token:", error);
      return false;
    }
  };

  const isLoggedIn = checkTokenValidity();
  
  return (
    <AuthProvider>
    <Router>
      <div className="App">
      {isLoggedIn ? <ProtectedHeader /> : <Header />}
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/become-reseller" element={<Apply />} />
            <Route path="/dashboard" element={<ProtectedRoute><Panel /></ProtectedRoute>} />
            <Route path="/dashboard/success" element={<PaymentSuccess />} />
            <Route path="/dashboard/cancel" element={<PaymentCancel />} />
            <Route path="/products" element={<ProtectedRoute><Products /></ProtectedRoute>} />
            <Route path="/activate" element={<Activate />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;
