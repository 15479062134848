import react from 'react';
import '../App.css';
import { Link } from 'react-router-dom'; 

export default function Footer() {
    return (
    <footer className="footer">
        <div className="footer-grid">
        <p>© 2024 SweatVPN | ByteFusion Sp. All rights reserved.</p>
        </div>
    </footer> 
    );
}
