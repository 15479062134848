import React from "react";

function Discord({ serverIcon, serverName, serverInvite, memberCount }) {
    return (
        <div className="discord">
            <div className="discord-section">
                <img src={serverIcon} alt="Discord" className="discord-icon" />
                <h5>Server Name: {serverName}</h5>
                <h5>Server Invite: <a href={serverInvite} target="_blank" rel="noopener noreferrer">{serverInvite}</a></h5>
                <h5>Members: {memberCount}</h5>
            </div>
        </div>
    );
}

export default Discord;
