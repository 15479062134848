import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Toast from './Toast';

const PaymentCancel = () => {
    const navigate = useNavigate();
    const [toastInfo, setToastInfo] = useState(null);

    useEffect(() => {
        setToastInfo({
            message: 'Payment was canceled.',
            type: 'info'
        });
        setTimeout(() => navigate('/dashboard'), 3000);
    }, [navigate]);

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}>
                <p style={{
                    color: 'white',
                    fontSize: '20px' 
                }}>
                    Loading...
                </p>
            </div>      
            {toastInfo && (
                <Toast
                    message={toastInfo.message}
                    type={toastInfo.type}
                    onClose={() => setToastInfo(null)}
                />
            )}
        </>
    );
};

export default PaymentCancel;
