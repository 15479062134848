import React, { useEffect } from 'react';

 
const Toast = ({ message, onClose, type }) => {
    useEffect(() => {
      const timer = setTimeout(onClose, 3000); 
      return () => clearTimeout(timer);
    }, [onClose]);
  
    const getToastStyle = () => {
      switch (type) {
        case 'success':
          return { backgroundColor: '#4BB543' }; 
        case 'error':
          return { backgroundColor: '#FF3333' }; 
        default:
          return { backgroundColor: '#FF3333' }; 
      }
    };
  
    return (
      <div className="toast" style={getToastStyle()}>{message}</div>
    );
  };
  
  export default Toast;
