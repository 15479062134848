import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Toast from './Toast';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const [toastInfo, setToastInfo] = useState(null);

  const fetchSuccessEndpoint = async () => {
    if (!sessionId) {
      console.error('Session ID is missing.');
      return;
    }

    try {
      const response = await fetch(`http://localhost:5050/dashboard/success/${sessionId}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.balanceUpdated) {
        setToastInfo({
          message: 'Payment successful. Balance updated.',
          type: 'success'
        });
      } else if (data.error) {
        setToastInfo({
          message: data.error,
          type: data.error === 'Balance already updated.' ? 'success' : 'error'
        });
      }
    } catch (error) {
      console.error('Error updating balance:', error);
      setToastInfo({
        message: 'Failed to update balance.',
        type: 'error'
      });
    } finally {
      setTimeout(() => navigate('/dashboard'), 3000);
    }
  };

  useEffect(() => {
    fetchSuccessEndpoint();
  }, [sessionId, navigate]);
  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
      }}>
        <p style={{
          color: 'white',
          fontSize: '20px'
        }}>Processing your payment...</p>
      </div>
      {toastInfo && (
        <Toast
          message={toastInfo.message}
          type={toastInfo.type}
          onClose={() => setToastInfo(null)}
        />
      )}
    </>
  );
};

export default PaymentSuccess;
