import { React, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import HamburgerIcon from "../assets/hamburger.png";
import CloseIcon from "../assets/delete.png";
import { Link } from 'react-router-dom';

const Hamburger = ({ user }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { setIsLoggedIn } = useAuth();

    const toggleNav = () => {
        setIsOpen(!isOpen);
    };
    
    const handleLogout = () => {
        localStorage.removeItem('authToken');
        setIsLoggedIn(false); 
        window.location.reload();
    }

    return (
        <div style={{ position: 'fixed', top: 0, right: 0, zIndex: 999, padding: '10px' }}>
            <img className="hamburger-icon"
                src={isOpen ? CloseIcon : HamburgerIcon}
                alt={isOpen ? "Close Menu" : "Open Menu"}
                onClick={toggleNav}
                style={{ cursor: 'pointer', width: '30px', height: '30px' }}
            />
            <div id="mySidenav" className="sidenav" style={{
                width: isOpen ? "100%" : "0",
                height: '100%',
                position: 'fixed',
                zIndex: 998,
                top: 0,
                right: 0,
                backgroundColor: '#211f2d',
                overflowX: 'hidden',
                transition: 'width 0.5s ease',
                paddingTop: '60px',
            }}>
                <img
                    src={CloseIcon}
                    alt="Close Menu"
                    onClick={() => setIsOpen(false)}
                    style={{ cursor: 'pointer', width: '20px', height: '20px', zIndex: 1002, display: '0 auto', position: 'absolute', top: '10px', right: '10px' }}
                />
                <a href="/" style={linkStyle}>Home</a>
                <h5 className="logged-in-user">Welcome {user}!</h5>
                <button className="btn-dashboard"> <Link to="/dashboard">Dashboard</Link></button>
                <nav className="logout-button">
                    <button className="btn-primary" onClick={handleLogout}>Logout</button>
                </nav>

            </div>
        </div>
    );
};

const linkStyle = {
    padding: '8px',
    textDecoration: 'none',
    fontSize: '25px',
    color: '#818181',
    display: 'block',
    transition: '0.3s'
};

export default Hamburger;
