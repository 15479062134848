import React, { useState } from 'react';
import Toast from './Toast';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const BalanceModal = ({ isOpen, onClose, resellerId, stripeCustomerId, email, firstName, lastName }) => {
  const [amount, setAmount] = useState('');
  const [toast, setToast] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const showToast = (message, type = 'default') => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000);
  };

  const checkTokenAndRedirect = (response) => {
    if (response.status === 401 || response.status === 403) {
      showToast("Session expired. Please login again.", 'error');
      setTimeout(() => navigate('/login'), 3000); // Redirect to login after showing the toast
      return true;
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!amount || parseFloat(amount) < 20) {
      showToast('Please enter an amount of $20 or more.', 'error');
      setIsLoading(false);
      return;
    }

    if (!stripeCustomerId) {
      try {
        const createCustomerResponse = await fetch('https://sweatvpn-resellers-backend-production.up.railway.app/api/create-customer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
          body: JSON.stringify({ resellerId, email, firstName, lastName }),
        });

        if (checkTokenAndRedirect(createCustomerResponse)) return; // Check token expiration

        const customerData = await createCustomerResponse.json();
        if (!createCustomerResponse.ok) {
          throw new Error(customerData.message || 'Failed to create customer');
        }
        stripeCustomerId = customerData.stripeCustomerId;
        showToast('Customer created successfully.', 'success');
      } catch (error) {
        showToast(error.message, 'error');
        setIsLoading(false);
        return;
      }
    }

    if(stripeCustomerId){
      try {
        const addBalanceResponse = await fetch('https://sweatvpn-resellers-backend-production.up.railway.app/api/add-balance', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
          body: JSON.stringify({
            resellerId,
            amount: parseFloat(amount),
            stripeCustomerId,
          }),
        });
  
        if (checkTokenAndRedirect(addBalanceResponse)) return; // Check token expiration

        const balanceData = await addBalanceResponse.json();
        if (!addBalanceResponse.ok) {
          throw new Error(balanceData.message || 'Failed to add balance');
        }
  
        if (balanceData.url) {
          window.location.href = balanceData.url; 
          return; 
        } else {
          showToast('Failed to retrieve payment URL.');
        }
      } catch (error) {
        console.error('Error adding balance:', error);
        showToast(error.message, 'error');
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="modal-backdrop">
        <div className="modal">
          <button className="close-button" onClick={() => { onClose(); setIsLoading(false); }}>X</button>
          <form onSubmit={handleSubmit}>
            <div className="input-balance">
              <label className="input-balance-label" htmlFor="amount">Amount to Add:</label>
              <input
                type="number"
                id="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                min="20"
                step="1"
              />
            </div>
            <p style={{ margin: '0 auto', fontSize: '14px' }}>Minimal amount is $20</p>
            <button className="balance-add-button" type="submit" disabled={isLoading}>Add Balance</button>
            {isLoading && <p style={{ margin: '0 auto', fontSize: '14px' }}>Processing...</p>}
          </form>
        </div>
      </div>
      {toast && <Toast message={toast.message} type={toast.type} onClose={() => setToast(null)} />}
    </>
  );
};

export default BalanceModal;
