import { React, useState, useEffect } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Hamburger from './HamburgerProtected';

export default function ProtectedHeader() {
    const { setIsLoggedIn } = useAuth();
    const [resellerId, setResellerId] = useState('');

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        setIsLoggedIn(false); 
        window.location.reload();
    }
    
    const [dashboardData, setDashboardData] = useState({
        email: '',
    });

    const fetchDashboardData = async () => {
        try {
            const response = await fetch('https://sweatvpn-resellers-backend-production.up.railway.app/api/dashboard', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });
            if (response.status === 401) {
                localStorage.removeItem('authToken');
                window.location.href = '/login';
                return;
            }
            if (!response.ok) throw new Error('Dashboard data fetch failed');
            if (response.ok) {
                const data = await response.json();
                setDashboardData(data);
                localStorage.setItem('userId', data.userId);
                setResellerId(data.resellerId);
            }
        } catch (error) {
            console.error("Error fetching dashboard data:", error);
        }
    };

    useEffect(() => {
        fetchDashboardData();
    }, []);

        return (
            <header className="logged-in-header">
                <Hamburger 
                user={dashboardData.email}
                />
                <Link to="/" className="header-mobile">Sweat VPN Reseller</Link>
                <nav className="header-navigation">
                <Link to="/" className="header-logo">Sweat VPN Reseller</Link>
                <h5 className="logged-in-user">Welcome {dashboardData.email}!</h5>
                <button className="btn-dashboard"> <Link to="/dashboard">Dashboard</Link></button>
                <nav className="logout-button">
                <button className="btn-primary" onClick={handleLogout}>Logout</button>
                </nav>
                </nav>
            </header>
        );
}