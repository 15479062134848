import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Email from '../assets/email.png';
import Password from '../assets/password.png';
import { useAuth } from '../contexts/AuthContext';
import Toast from '../components/Toast';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { setIsLoggedIn } = useAuth();
    const navigate = useNavigate();
    const [toastInfo, setToastInfo] = useState(null);

    const showToast = (message, type = 'default') => {
        setToastInfo({ message, type });
        setTimeout(() => setToastInfo(null), 3000);
    };

    const loginHandler = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://sweatvpn-resellers-backend-production.up.railway.app/api/reseller/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
            const data = await response.json();
            const vertificationCode = data.verificationCode;
            if (response.status === 404) {
                showToast('User not found');
            } else if (response.ok) {
                localStorage.setItem('authToken', data.token);
                if (!data.activated) {
                    navigate(`/activate?code=${vertificationCode}`);
                } else {
                    console.log('Login successful', data);
                    setIsLoggedIn(true);
                    navigate('/dashboard');
                    window.location.reload();
                }
            } else {
                console.error('Login failed:', data.error);
            }
        } catch (error) {
            console.error('Network error:', error);
        }
    };

    return (
        <div className="login">
            <div className="login-container">
                <h1>Login</h1>
                <form onSubmit={loginHandler}>
                    <div className="input" type="email">
                        <img src={Email} alt="email" />
                        <input
                            type="email"
                            placeholder='Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="input" type="password">
                        <img src={Password} alt="password" />
                        <input
                            type="password"
                            placeholder='Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="button-group">
                        <button type="submit" className="login-button" style={{ marginRight: '30px' }}>Login</button>
                        <button className="join-button"><Link to="/become-reseller">Join Now</Link></button>
                    </div>
                </form>
            </div>
            {toastInfo && (
                <Toast
                    message={toastInfo.message}
                    type={toastInfo.type}
                    onClose={() => setToastInfo(null)}
                />
            )}
        </div>
    )
}