import React from 'react';
import Delivery from '../assets/fast-delivery.png';
import Prices from '../assets/price-tag.png';
import Help from '../assets/help.png';
import Shield from '../assets/shield.png';
import Order from '../assets/order.png';
import Setting from '../assets/settings.png';
import { Link } from 'react-router-dom';
export default function Home() {
    return (
        <div className="home">
            <div className="home-container">
                <h2> Welcome to Sweat VPN Reseller</h2>
                <p className="home-subtext">ByteFusion Sp. is now presenting a reseller plan for its entire product range, including SweatVPN, SweatPlay, and SweatConsole. If you manage a Discord server, our reseller plan is the perfect match for you! To kickstart your journey, all new resellers receive a $20 free bonus.</p>
                <button className="join-button" style={{marginRight: '30px'}}><Link to="/become-reseller">Join Now</Link></button>  
                <button className="login-button"><Link to="/login">Login</Link></button>  
            </div>
            <div className="home-second-container">
            <h2>Why Reselling At SweatVPN?</h2>
            <p className="home-subtext">If you're looking to offer the top Call of Duty-related products on the market and earn money in the process, you've come to the right place. Our reseller plan is quick, easy, and ensures instant delivery. Receive your license keys in just a few clicks!</p>
            <div className="grid-section">
                <div className="grid-item">
                    <img src={Prices} alt="icon" />
                    <h3>Low Prices</h3>
                    <p className="grid-item-text">We guarantee the lowest prices!</p>
                </div>
                <div className="grid-item">
                <img src={Help} alt="icon" />
                    <h3>Fast Support</h3>
                    <p className="grid-item-text">Our support is incredibly fast!</p>
                </div>
                <div className="grid-item">
                <img src={Order} alt="icon" />
                    <h3>Instant Delivery</h3>
                    <p className="grid-item-text">Your products are delivered instantly.</p>
                </div>
                <div className="grid-item">
                <img src={Setting} alt="icon" />
                    <h3>Automated</h3>
                    <p className="grid-item-text">Our system operates automatically.</p>
                </div>
                <div className="grid-item">
                <img src={Delivery} alt="icon" />
                    <h3>Amazing Products</h3>
                    <p className="grid-item-text">We only offer the best products on the market.</p>
                </div>
                <div className="grid-item">
                    <img src={Shield} alt="icon" />
                    <h3>Safe & Fast</h3>
                    <p className="grid-item-text">All our products are safe and optimized for speed.</p>
                </div>
            </div>
            </div>
        </div>
    );
}

