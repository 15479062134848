import React, { useState, useEffect } from 'react';
import Key from '../assets/key.png';
import Purchases from '../assets/purchase.png';
import Users from '../assets/user.png';
import Discord from '../components/discord';
import Modal from '../components/PurchaseModal';
import { Link } from 'react-router-dom';
import Balance from '../components/BalanceModal';
import Toast from '../components/Toast';
import Money from '../assets/money.png';

export default function Panel() {
    const [showModal, setShowModal] = useState(false);
    const handleModalOpen = () => setShowModal(true);
    const [showBalanceModal, setShowBalanceModal] = useState(false);
    const handleBalanceOpen = () => setShowBalanceModal(true);
    const [selectedKey, setSelectedKey] = useState(null);
    const [toastInfo, setToastInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [resellerId, setResellerId] = useState('');
    const [stripeCustomerId, setStripeCustomerId] = useState('');
    const [successPurchase, setSuccessPurchase] = useState(false);

    const showToast = (message, type = 'default') => {
        setToastInfo({ message, type });
        setTimeout(() => setToastInfo(null), 3000);
    };


    const fetchKeyDetails = async (key, productName) => {
        let url = '';
        if (productName === 'SweatVPN' || productName === 'SweatConsole') {
            url = `https://vataos1-production.up.railway.app/valid/${key}`;
        } else if (productName === 'SweatPlay') {
            url = `https://vataos1-production.up.railway.app/validsp/${key}`;
        } else {
            console.error('Unsupported product name:', productName);
            return null;
        }

        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`Failed to fetch key details from ${url}`);
            return response.json();
        } catch (error) {
            console.error(`Error fetching key details for ${key}:`, error);
            return null;
        }
    };

    const resetHwidForSelectedKey = async () => {
        const sweatvpnUrl = `https://vataos1-production.up.railway.app/api/v1/resetvpn/${selectedKey.key}`;
        const sweatplayUrl = `https://vataos1-production.up.railway.app/api/v1/resetsp/${selectedKey.key}`;
        try {
            let response;
            if (selectedKey.productName === 'SweatVPN' || selectedKey.productName === 'SweatConsole') {
                response = await fetch(sweatvpnUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                });
            } else if (selectedKey.productName === 'SweatPlay') {
                response = await fetch(sweatplayUrl, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                });
            }
            if (response || response.status === 200) {
                showToast('HWID reset successfully.', 'success');
            } else {
                showToast('Failed to reset HWID.', 'error');
            }
        } catch (error) {
            console.error('Error resetting HWID:', error);
            showToast('Error occurred while resetting HWID.', 'error');
        }

        setSelectedKey(null);
    };

    const handleKeyClick = (key) => {
        setSelectedKey(selectedKey && selectedKey.key === key.key ? null : key);
    };

    const [dashboardData, setDashboardData] = useState({
        balance: 0,
        usersCount: 0,
        purchasedKeys: [],
        serverName: '',
        serverInvite: '',
        memberCount: 0,
        serverIcon: '',
        stripeCustomerId: '',
        firstName: '',
        lastName: '',
        email: '',
        resellerId: '',
    });

    const [displayCount, setDisplayCount] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const pageCount = Math.ceil(dashboardData.purchasedKeys.length / displayCount);

    const handleModalClose = () => {
        if (successPurchase) {
            showToast('Key purchased successfully!', 'success');
            setSuccessPurchase(false);
        }
        fetchDashboardAndKeyDetails();
        setShowModal(false);
    };

    const handleBalancClose = () => {
        setShowBalanceModal(false);
    };

    const handleDisplayCountChange = (e) => setDisplayCount(Number(e.target.value));
    const goToPreviousPage = () => setCurrentPage(currentPage - 1);
    const goToNextPage = () => setCurrentPage(currentPage + 1);

    const tokenExpiryCheck = () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            window.location.href = '/login';
        } else {
            const tokenExpirationTime = JSON.parse(atob(token.split('.')[1])).exp * 1000;
            const currentTime = Date.now();
            if (currentTime > tokenExpirationTime) {
                localStorage.removeItem('authToken');
                window.location.href = '/login';
            }
        }
    };

    const fetchDashboardAndKeyDetails = async () => {
        setIsLoading(true);
        if (isLoading) {
            return <div>Loading keys...</div>;
        }
        try {
            const dashboardResponse = await fetch('https://sweatvpn-resellers-backend-production.up.railway.app/api/dashboard', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
            });

            if (!dashboardResponse.ok) throw new Error('Failed to fetch dashboard data');
            const dashboardData = await dashboardResponse.json();
            setDashboardData(dashboardData);
            console.log(dashboardData);
            setResellerId(dashboardData.resellerId);
            if (dashboardData.purchasedKeys.length > 0) {
                const keysWithDetails = await Promise.all(
                    dashboardData.purchasedKeys.map(async (key) => {
                        try {
                            const details = await fetchKeyDetails(key.key, key.productName);
                            return { ...key, details };
                        } catch (error) {
                            console.error(`Error fetching details for key ${key.key}:`, error);
                            return { ...key, details: null };
                        }
                    })
                );
                setDashboardData({ ...dashboardData, purchasedKeys: keysWithDetails });
            }

        } catch (error) {
            console.error("Error:", error);
            showToast('Error fetching dashboard data.', 'error');
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchDashboardAndKeyDetails();
        tokenExpiryCheck();
    }, []);


    return (
        <div className="panel">
            <h1 className="panel-header">Dashboard</h1>
            <div className="status-grids">
                <div className="status-grid">
                    <img src={Money} alt="Money" className="status-icon" />
                    <div className="text-content">
                        <h1>Balance</h1>
                        <p>${dashboardData.balance}</p>
                    </div>
                </div>
                <div className="status-grid" style={{ backgroundColor: '#23b297' }}>
                    <img src={Users} alt="person" className="status-icon" />
                    <div className="text-content">
                        <h1>Users</h1>
                        <p>{dashboardData.purchasedKeys.length}</p>
                    </div>
                </div>
                <div className="status-grid" style={{ backgroundColor: '#e26498' }}>
                    <img src={Purchases} alt="person" className="status-icon" />
                    <div className="text-content">
                        <h1>Purchased</h1>
                        <p>{dashboardData.purchasedKeys.length}</p>
                    </div>
                </div>
            </div>
            <Discord
                serverIcon={dashboardData.serverIcon}
                serverName={dashboardData.serverName}
                serverInvite={dashboardData.serverInvite}
                memberCount={dashboardData.memberCount}
            />
            <div className="key-table">
                <div className="btn-group">
                    <button className="btn" onClick={handleBalanceOpen}><strong>Add Balance</strong></button>
                    <button className="btn-2" onClick={handleModalOpen}><strong>Purchase Key</strong></button>
                    <button className="btn-3" disabled={!selectedKey} onClick={() => resetHwidForSelectedKey()}><strong>Reset HWID</strong></button>
                    <button className="btn-3"><strong> <Link to="/products">Products</Link></strong></button>
                </div>

                <Modal
                    isOpen={showModal}
                    onClose={handleModalClose}
                    resellerId={resellerId}
                    onPurchaseSuccess={() => {
                        setSuccessPurchase(true); 
                    }} />

                <Balance
                    isOpen={showBalanceModal}
                    onClose={handleBalancClose}
                    resellerId={resellerId}
                    stripeCustomerId={dashboardData.stripeCustomerId}
                    firstName={firstName}
                    lastName={lastName}
                    email={email}
                />
                <div className="key-table">
                    <div className="display-count-selector">
                        <label htmlFor="displayCount">Show: </label>
                        <select style={{ padding: '5px', backgroundColor: '#222', color: 'aliceblue', borderRadius: '5px', borderStyle: 'none', marginRight: '5px' }} id="displayCount" value={displayCount} onChange={handleDisplayCountChange}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                        </select>
                        keys
                    </div>
                    <div className="key-info-header">
                        <h5>Product</h5>
                        <h5>Key</h5>
                        <h5>Used</h5>
                        <h5>HWID</h5>
                        <h5>Last Login</h5>
                    </div>
                    {dashboardData.purchasedKeys.slice((currentPage - 1) * displayCount, currentPage * displayCount).map((key, index) => (
                        <div key={index} className={`key-row ${selectedKey && selectedKey.key === key.key ? 'selected-key' : ''}`} onClick={() => handleKeyClick(key)}>
                            <img src={Key} alt="Key" className="key-icon" />
                            <div className="key-text-content">
                                <h5>{key.productName}</h5>
                                <h5>{key.key}</h5>
                                {key.details ? (
                                    <div className='key-details'>
                                        <h5>{key.details.used ? 'Yes' : 'No'}</h5>
                                        <h5>{key.details.hwid ? key.details.hwid : 'N/A'}</h5>
                                        <h5>{key.details.lastLogin ? new Date(key.details.lastLogin).toLocaleString() : 'N/A'}</h5>
                                    </div>
                                ) : (
                                    <p>Loading details...</p>
                                )}
                            </div>
                        </div>
                    ))}
                    <div className="pagination-controls">
                        <button onClick={goToPreviousPage} disabled={currentPage === 1 || dashboardData.purchasedKeys.length === 0}>Previous</button>
                        <span>Page {currentPage} of {pageCount}</span>
                        <button onClick={goToNextPage} disabled={currentPage === pageCount || dashboardData.purchasedKeys.length === 0}>Next</button>
                    </div>
                </div>
            </div>
            {toastInfo && (
                <Toast
                    message={toastInfo.message}
                    type={toastInfo.type}
                    onClose={() => setToastInfo(null)}
                />
            )}
        </div>

    );
}

