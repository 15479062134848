import React, { useState, useEffect } from 'react';
import Toast from './Toast'; 
import { useNavigate } from 'react-router-dom';

const PurchaseModal = ({ isOpen, onClose, resellerId, onPurchaseSuccess }) => {
  const [product, setProduct] = useState('');
  const [duration, setDuration] = useState('');
  const [quantity, setQuantity] = useState(1); 
  const [totalPrice, setTotalPrice] = useState(0); 
  const [toastInfo, setToastInfo] = useState(null);
  const [successPurchase, setSuccessPurchase] = useState(false);
  const [stockInfo, setStockInfo] = useState({});

  const prices = {
    SweatVPN: { '1': 7.5, '2': 15, '3': 20, '12': 70 },
    SweatConsole: { '1': 7.5, '2': 15, '3': 20, '12': 70 },
    SweatPlay: { '1': 7.5, '2': 15, '3': 20, '12': 70 },
  };

  const showToast = (message, type = 'default') => {
    setToastInfo({ message, type });
    setTimeout(() => setToastInfo(null), 3000); 
  };

  const fetchStock = async () => {
    try {
        const response = await fetch('https://sweatvpn-resellers-backend-production.up.railway.app/api/stock');
        const data = await response.json();
        setStockInfo(data);
    } catch (error) {
        console.error("Failed to fetch stock information:", error);
        showToast('Failed to fetch stock information.', 'error');
    }
};

  useEffect(() => {
    if (product && duration) {
      const pricePerUnit = prices[product][duration];
      const total = pricePerUnit * quantity;
      setTotalPrice(total);
    }
    fetchStock(); 
  }, [product, duration, quantity]);



  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const purchaseResponse = await fetch('https://sweatvpn-resellers-backend-production.up.railway.app/api/purchase', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          resellerId,
          product,
          duration: parseInt(duration, 10),
          quantity: parseInt(quantity, 10),
        }),
      });

      const purchaseData = await purchaseResponse.json();

      if (purchaseResponse.ok) {
        showToast('Purchase successful!', 'success'); 
        if (typeof onPurchaseSuccess === 'function') {
          onPurchaseSuccess(); 
        }
        onClose(); 
      }
      if(purchaseResponse.status === 500) {
        throw new Error(`Purchase failed! ${purchaseData.message}.`);
      }
        else {
        throw new Error(`Purchase failed! ${purchaseData.message}.`);
      }
    } catch (error) {
      showToast(`${error.message}`);
    }
  };

  if (!isOpen) return null;
  const availableStock = stockInfo[product] && stockInfo[product][duration] !== undefined ? stockInfo[product][duration] : 'N/A';

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <button className="close-button" onClick={onClose}>X</button>
        <form onSubmit={handleSubmit}>
          <select value={product} onChange={(e) => setProduct(e.target.value)}>
            <option value="">Select Product</option>
            <option value="SweatVPN">SweatVPN</option>
            <option value="SweatConsole">SweatConsole</option>
            <option value="SweatPlay">SweatPlay</option>
          </select>
          <select value={duration} onChange={(e) => setDuration(e.target.value)}>
            <option value="">Select Duration</option>
            <option value="1">1 month - $7.5</option>
            <option value="2">2 months - $15</option>
            <option value="3">3 months - $20</option>
            <option value="12">1 year - $70</option>
          </select>
          <select value={quantity} onChange={(e) => setQuantity(e.target.value)}>
            <option value="1">1 Key</option>
            <option value="2">2 Keys</option>
            <option value="3">3 Keys</option>
            <option value="4">4 Keys</option>
            <option value="5">5 Keys</option>
          </select>
          <div>Total Price: ${totalPrice.toFixed(2)} (Available Stock: {availableStock})</div>
          <button className="purchase-button" type="submit">Purchase</button>
        </form>
      </div>
      {toastInfo && (
        <Toast
          message={toastInfo.message}
          type={toastInfo.type}
          onClose={() => setToastInfo(null)}
        />
      )}
    </div>
  );
};

export default PurchaseModal;