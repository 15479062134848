import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export default function Activate() {
    const navigate = useNavigate();
    const query = useQuery();
    const [activationCode, setActivationCode] = useState('');

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            navigate('/login');
        }

        const code = query.get('code');
        if (code) {
            setActivationCode(code);
        } else {
            navigate('/');
        }
    }, [navigate, query]);

    const activationHandler = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://sweatvpn-resellers-backend-production.up.railway.app/api/reseller/activate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ activationCode }),
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.getItem('authToken', data.token);
                navigate('/dashboard');
            } else {
                console.error('Activation failed:', data.message);
                alert('Activation failed: ' + data.message); 
            }
        } catch (error) {
            console.error('Network error:', error);
        }
    };

    return (
        <div className="activate">
            <div className="activate-container">
                <h1>Activate</h1>
                <form onSubmit={activationHandler}>
                    <div className="input">
                        <input
                            type="text"
                            placeholder='Activation Code'
                            value={activationCode}
                            onChange={(e) => setActivationCode(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="activate-button">Activate</button>
                </form>
            </div>
        </div>
    );
}