import React, { useEffect, useState } from 'react';

export default function Products() {
    const [sweatvpnVersionNumber, setSweatvpnVersionNumber] = useState('');
    const [sweatconsoleVersionNumber, setSweatconsoleVersionNumber] = useState('');
    const [sweatplayVersionNumber, setSweatplayVersionNumber] = useState('');

    useEffect(() => {
        const fetchVersionNumber = async (url) => {
            try {
                const response = await fetch(url);
                const data = await response.text(); 
                const match = data.match(/<version>(.*?)<\/version>/);
                return match ? match[1] : "N/A";
            } catch (error) {
                console.error(`Error fetching version from ${url}:`, error);
                return "Error fetching version";
            }
        };
        fetchVersionNumber('https://vataos1-production.up.railway.app/api/v1/sweatvpn.xml').then(setSweatvpnVersionNumber);
        fetchVersionNumber('https://vataos1-production.up.railway.app/api/v1/sweatconsole.xml').then(setSweatconsoleVersionNumber);
        fetchVersionNumber('https://sweatplay-downloads-production.up.railway.app/api/product/sweatplay/sweatplay.xml').then(setSweatplayVersionNumber);
    }, [])

    const sweatvpnDownload = () => {
        window.open('https://cdn.discordapp.com/attachments/1127145290433052702/1218205790960222208/Sweat_VPN_Advanced_Call_of_Duty_Gaming_VPN.zip?ex=6606d18d&is=65f45c8d&hm=2652d4640e31c653e45f54c79b524646f55d327b4964c3e01b0ad3da96521701&');
    }

    const sweatplayDownload = () => {
        window.open('https://sweatplay-downloads-production.up.railway.app/api/product/sweatplay/sweatplay_setup.zip');
    }

    const sweatconsoleDownload = () => {
        window.open('https://drive.google.com/file/d/1SVy00Uc8Eo0RtoFgGAOl6DAAmP-RaF8X/view?usp=sharing');
    }

    return (
        <div className="products">
            <h1 className="products-header">Products</h1>
            <div className="products-group">
                <div className="product-grid">
                    <h5 className="product-title">SweatVPN</h5>
                    <h5 className="product-version">Version: {sweatvpnVersionNumber}</h5>
                    <button className='sweatvpn-download-btn' onClick={sweatvpnDownload}>Download</button>
                </div>
                <div className="product-grid">
                    <h5 className="product-title">SweatPlay</h5>
                    <h5 className="product-version">Version: {sweatplayVersionNumber}</h5>
                    <button className='sweatplay-download-btn' onClick={sweatplayDownload}>Download</button>
                </div>
                <div className="product-grid">
                    <h5 className="product-title">SweatConsole</h5>
                    <h5 className="product-version">Version: {sweatconsoleVersionNumber}</h5>
                    <button className='sweatconsole-download-btn' onClick={sweatconsoleDownload}>Download</button>
                </div>
            </div>
        </div>
    );
}