import React from 'react';
import '../App.css';
import { Link, useNavigate  } from 'react-router-dom';
import Hamburger from './Hamburger';

export default function Header() {
    const navigate = useNavigate();
    const handleLogin = () => {
      navigate("/login");
    };

    return (
        <header className="header">
            <Link to="/" className="header-logo">Sweat VPN Reseller</Link>
            <Hamburger />
            <nav className="header-navigation">
                <Link to="/">Home</Link>
                <Link to="/become-reseller">Become Reseller</Link>
                <Link to="https://discord.gg/sweatvpn">Discord</Link>
                <button className="btn-primary" onClick={handleLogin}>Login</button>
            </nav>
        </header>
    );
}